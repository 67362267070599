
import axios from 'axios';
import { computed, defineComponent, ref } from 'vue';
import { lostelkUrl } from '../../global';
import router from '../../router';
import store from '../../store';
import createTooltip from '../globalFun/createTooltip';

export default defineComponent({
  name: 'EditAvatar',
  components: {},
  setup() {
    const userData = computed(() => store.state.user);
    const fileRef = ref();
    const fileData = ref();

    /**
     *  预览图像
     **/
    const imagePreviewUrl = ref();

    const createImagePreview = (file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        imagePreviewUrl.value = event.target?.result;
      };
    };

    /**
     * 获取图像数据
     */
    const onChangeFile = (event: Event) => {
      // 拿到当前dom节点
      const currenTarget = event.target as HTMLInputElement;
      if (currenTarget.files) {
        // 拿到第一张图片
        const file = currenTarget.files[0];
        if (file) {
          fileData.value = file;
          createImagePreview(file);
        }
      }
    };

    /**
     * 上传头像
     */
    const createFile = async (file: File) => {
      // 创建表单
      const formData = new FormData();

      // 添加字段
      formData.append('avatar', file);

      // 上传文件
      try {
        await axios.post('/avatar', formData);

        // 清理
        imagePreviewUrl.value = null;
        fileRef.value.value = '';
        fileData.value = null;

        await createTooltip('Change user avatar success', 'success', 1000);

        setTimeout(() => {
          router.go(0);
        }, 1000);
      } catch (error) {
        await createTooltip(
          'Upload failed, your photos may not meet the requirements or network fluctuations',
          'error',
          3000
        );
      }
    };

    /**
     * 提交
     */
    const submitFrom = () => {
      createFile(fileData.value);
    };

    /**
     * 取消
     */
    const cancel = () => {
      imagePreviewUrl.value = null;
      fileRef.value.value = '';
      fileData.value = null;
    };

    return {
      userData,
      lostelkUrl,
      onChangeFile,
      imagePreviewUrl,
      fileRef,
      submitFrom,
      cancel
    };
  }
});
