
import { defineComponent } from 'vue';

import EditUserName from '../components/editAccount/EditUserName.vue';
import EditUserPassword from '../components/editAccount/EditUserPassword.vue';
import EditAvatar from '../components/editAccount/EditAvatar.vue';
import EditSynopsis from '../components/editAccount/EditSynopsis.vue';

export default defineComponent({
  name: 'EditAccount',
  components: {
    EditUserName,
    EditUserPassword,
    EditAvatar,
    EditSynopsis,
  },
  setup() {
    return {};
  },
});
